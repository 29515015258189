<template>
<div class=" d-flex w-100">
    <div class="block">
        <p class="digit">{{ days  }}</p>
        <p class="text">Days</p>
    </div>
    <div class="block">
        <p class="digit">{{ hours  }}</p>
        <p class="text">Hours</p>
    </div>
    <div class="block">
        <p class="digit">{{ minutes  }}</p>
        <p class="text">Minutes</p>
    </div>
    <div class="block">
        <p class="digit">{{ seconds  }}</p>
        <p class="text">Seconds</p>
    </div>
</div>
</template>

<script>
export default {
    mounted() {
        window.setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);
        }, 1000);
    },
    props: {
        d: String
    },
    data() {
        return {
            now: Math.trunc((new Date()).getTime() / 1000),
            date: Math.trunc((new Date(this.d)).getTime() / 1000),
        }
    },
    computed: {
        seconds() {
            return (this.date - this.now) % 60;
        },
        minutes() {
            return Math.trunc((this.date - this.now) / 60) % 60;
        },
        hours() {
            return Math.trunc((this.date - this.now) / 60 / 60) % 24;
        },
        days() {
            return Math.trunc((this.date - this.now) / 60 / 60 / 24);
        }
    }
}
</script>

<style>
/* @import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400|Roboto:100); */
.block {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.text {
    color: #1abc9c;
    font-size: 10px;
    /* font-family: 'Roboto Condensed', serif; */
    font-weight: 400;
    /* margin-top:10px; */
    margin: 0;
    /* margin-bottom: 10px; */
    text-align: center;
}

.digit {
    color: #ecf0f1;
    font-size: 20px;
    font-weight: 100;
    /* font-family: 'Roboto', serif; */
    margin: 0px;
    margin-bottom: 0px;
    text-align: center;
}
</style>
