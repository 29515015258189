<template>
<div>
    <div style="position:fixed; " class="over image"></div>
    <div style="position:fixed; " class="over"></div>
    <div style="top:0;position:relaive;position:relative;">
        <div class="d-flex justify-content-between container py-4 align-items-center ">
            <img src="../assets/before_shoppy.png" height="50px" />
            <a href="//dl.shoppy.cm" class="btn bg-white fw-bold btn-sm px-3 py-2">Download App</a>
        </div>

        <br />
        <div class="container">
            <div class=" row">
                <div class=" col-sm-12 col-md-5 col-lg-4 col-xl-3 my-2  ">
                    <!-- background-color:#272a35 -->
                    <div style="border-radius:15px;background:rgba(0,0,0,0.38)" class="p-3" v-if="data.length>0&&meta.end">
                        <small style="color:rgba(255,255,255,0.4)">NEXT PROBABLE WINNER IN</small>

                        <countdown :d="meta.end"></countdown>
                        <!-- {{this.meta.end}} -->
                        <div class="d-flex py-3">
                            <div class="d-flex align-items-center">
                                <div class=" d-flex justify-content-center align-items-center with-shadow" style="height:70px;width:70px;background:rgba(0, 57, 118, 1);border-radius:30000px 30000px 0 30000px">
                                    <h1 class=" text-white m-0"><strong>{{data[0].username[0]}}</strong></h1>
                                </div>
                            </div>
                            <div class=" flex-grow-1 d-flex  justify-content-center px-2 flex-column">
                                <strong class=" text-white px-2">#{{data[0].username}}</strong>
                                <h4 class=" text-white px-2 text-white-50">{{data[0].points}} SP</h4>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="d-sm-none d-md-block d-none ">
                        <timeline>
                            <timeline-title>How credits are calculated</timeline-title>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">A: Products visited </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">B: Shops Visited </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">C: Statuses seen </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">D: Shops Subscribed </timeline-item>
                            <!-- <timeline-item bg-color="rgba(0, 57, 118, 1)">Product in wishlist  [x 0.01]</timeline-item> -->
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">E:Completed orders </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">F:Affiliated user accounts created </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">G:Affiliated shops created </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">H:Other hidden parameters</timeline-item>
                            <!-- <timeline-item bg-color="rgba(0, 57, 118, 1)">Shops rated  [x 0.01]</timeline-item> -->
                            <!-- <timeline-item bg-color="rgba(0, 57, 118, 1)">Shops rated  [x 0.01]</timeline-item> -->
                            <!-- <timeline-item :hollow="true">Item 22</timeline-item> -->
                        </timeline>
                    </div>
                </div>
                <!-- #0e5783 -->
                <div class=" col-sm-12 col-md-7 col-lg-8 col-xl-9 my-2">
                    <div style="background:rgba(0, 57, 118, 1);border-radius:10px;" class="p-4 with-shadow d-flex align-items-center justify-content-between flex-wrap d-sm-none d-md-block d-none ">
                        <div class="px-3 w-75 flex-grow-1" style="padding-left:0!important">
                            <h3 class=" text-white">Join the Shoppy game</h3>
                            <img src="../assets/before_underline.svg" height="10px" class="mb-2" />
                            <p class=" text-white my-2">The more you use and interact in the app, the more you earn points that can be converted to reductions to buy products.</p>

                            <a href="//dl.shoppy.cm" target="_blank" class="btn bg-white fw-light btn px-3 my-3" style="border-radius:400px;">Download App</a>
                        </div>
                        <!-- <center> <img src="../assets/bonhomme-Shoppy.png" height="200px" /></center> -->
                    </div>

                    <br />
                    <br />
                    <div style="" class="d-flex justify-content-between align-items-center mb-3">
                        <h3 class=" text-white ">{{loading?"Chargement...":"Classement"}}
                            <i class="fa fa-circle-o-notch fa-spin" v-if="loading"></i>
                            <i class="fa fa-refresh mx-2" @click="retrieve" title="Actualiser|Reload" style="cursor:pointer" v-else></i>
                        </h3>
                        <div>
                            <VueSlideBar v-model="page" style="width:100px;margin-top:-30px; min-height:50px!important" :min="1" :max="page_size" @callbackRange="callbackRange"></VueSlideBar>
                            <div style="color:rgba(255,255,255,0.6)" class="mt-2"><span><strong>Page:</strong> <span>{{page}} / {{page_size}}</span></span></div>
                        </div>
                    </div>

                    <div style="background-color:rgba(0,0,0,0.4)" class="p-3 mt-3">
                        <!-- <hr style="background:rgba(255,255,255,1);border-color:rgba(255,255,255,1)"/> -->
                        <!-- <div class="py-2" style="border-bottom:1px solid rgba(255,255,255,0.6)">

              </div> -->
                        <table class=" table" v-if="data.length>0">
                            <tr style="border-bottom:1px solid rgba(255,255,255,0.1);color:rgba(255,255,255,0.6);height:60px" v-for="(dt,b) in paginate(data,10,page)" :key="'pp'+b">
                                <td>#{{dt.pos}}</td>
                                <td>
                                    <span class="fw-bold" :style="{color:colorFromPos(dt.pos)}">#{{dt.username}}</span> {{dt.pos==1 && meta.end?'[Probable winner]':''}}
                                </td>
                                <td>
                                    <center class=" text-white-50">{{dt.points}} SP</center>
                                </td>
                                <td class="d-sm-none d-md-table-cell d-none">
                                    <center>{{dt.city}}</center>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div style="" class="d-flex justify-content-between align-items-center my-5 ">
                        <h3 class=" text-white "></h3>
                        <div>
                            <VueSlideBar v-model="page" style="width:100px;margin-top:-30px; min-height:50px!important" :min="1" :max="page_size" @callbackRange="callbackRange"></VueSlideBar>

                            <div style="color:rgba(255,255,255,0.6)" class="mt-2"><span><strong>Page:</strong> <span>{{page}} / {{page_size}}</span></span></div>
                        </div>
                    </div>
                </div>

                <div class=" col-sm-12 col-md-5 col-lg-4 col-xl-3 my-2  ">
                    <!-- background-color:#272a35 -->

                    <br />

                    <div class="d-sm-block d-md-none d-block">
                        <timeline>
                            <timeline-title>How credits are calculated</timeline-title>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">A: Products visited </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">B: Shops Visited </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">C: Statuses seen </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">D: Shops Subscribed </timeline-item>
                            <!-- <timeline-item bg-color="rgba(0, 57, 118, 1)">Product in wishlist  [x 0.01]</timeline-item> -->
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">E:Completed orders </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">F:Affiliated user accounts created </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">G:Affiliated shops created </timeline-item>
                            <timeline-item bg-color="rgba(0, 57, 118, 1)">H:Other hidden parameters</timeline-item>

                            <!-- <timeline-item bg-color="rgba(0, 57, 118, 1)">Shops rated  [x 0.01]</timeline-item> -->
                            <!-- <timeline-item bg-color="rgba(0, 57, 118, 1)">Shops rated  [x 0.01]</timeline-item> -->
                            <!-- <timeline-item :hollow="true">Item 22</timeline-item> -->
                        </timeline>
                        <div style="background:rgba(0, 57, 118, 1);border-radius:10px;" class="p-4 with-shadow d-flex align-items-center justify-content-between flex-wrap  ">
                            <div class="px-3 w-100" style="padding-left:0!important">
                                <h3 class=" text-white">Join the game</h3>
                                <img src="../assets/before_underline.svg" height="10px" class="mb-2" />
                                <p class=" text-white my-2">The more you use and interact in the app, the more you earn points that can be converted to reductions to buy products.</p>

                                <a href="//dl.shoppy.cm" target="_blank" class="btn bg-white fw-light btn px-3 my-3" style="border-radius:400px;">Download App</a>
                            </div>
                            <center> <img src="../assets/bonhomme-Shoppy.png" height="200px" /></center>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import VueSlideBar from 'vue-slide-bar'
import {
    Timeline,
    TimelineItem,
    TimelineTitle
} from 'vue-cute-timeline'
import 'vue-cute-timeline/dist/index.css'
import axios from 'axios';
import Countdown from '../components/countdown.vue'
export default {
    name: 'Home',
    components: {
        VueSlideBar,
        Timeline,
        TimelineItem,
        TimelineTitle,
        Countdown
    },
    data() {
        return {
            loading: false,
            data: [],
            meta: {
                end: null
            },
            page: 1,
            maxPages: 1,

        }
    },
    mounted() {
        this.retrieve();
    },
    methods: {
        retrieve() {
            this.loading = true;
            axios.get("https://www.shoppyit.com/api/v2/open/games-classification").then((e) => {
                // console.log(e.data.dat);
                this.data = e.data.data.list;
                this.meta = {
                    end: e.data.data.end.date
                }
                let numberofpages = 1;
                if ((e.data.data.list.length % 10) == 0) {
                    numberofpages = e.data.data.list.length / 10;
                } else {

                    numberofpages = Math.floor(e.data.data.list.length / 10) + 1;
                }
                if (numberofpages > this.page) { //if he advanced too much, return
                    this.page = 1;
                }
                this.page_size = numberofpages;
            }).catch(() => {
                alert('Could not reach the Shoppy Server, Please try to refresh!')
            }).finally(() => {
                this.loading = false;
            })
        },
        paginate(array, page_size, page_number) {
            // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        },
        colorFromPos(pos) {
            switch (pos) {
                case 1:
                    return '#d95702';
                case 2:
                    return '#ae5e35';
                case 3:
                    return '#80666a';
                case 4:
                    return '#4e6ea4';
                case 5:
                    return '#2775d2';
                default:
                    return '#007bff';
            }
        }
    }
}
</script>

<style>
.over {
    margin-left: -20px;
    margin-top: -20px;
    top: -20;
    left: -20;
    bottom: -20;
    right: -20;
    height: calc(100% + 150px);
    width: calc(100% + 150px);
    filter: blur(0px);
    opacity: 0.9;
    background: rgb(0, 57, 118);
    background: linear-gradient(69deg, rgba(6, 11, 19, 0.96) 12%, rgba(6, 11, 19, 1) 85%, rgba(0, 57, 118, 1) 100%);
}

.over.image {
    background: url("../assets/bgimage.jpeg");
    background-repeat: none;
    background-size: cover;
    opacity: 1;
    filter: blur(10px);
}

a {
    animation: all ease 0.6s;
}

a.bg-white:hover {
    border: 1px solid white;
    background: transparent !important;
    ;
    color: white;
}

.with-shadow {
    box-shadow: 0px 0px 44px 3px rgba(0, 57, 118, 0.34);
    -webkit-box-shadow: 0px 0px 44px 3px rgba(0, 57, 118, 0.34);
    -moz-box-shadow: 0px 0px 44px 3px rgba(0, 57, 118, 0.34);
}
</style>
